import React from "react";

const About = () => {
  return (
    <div className="m-auto w-full">
      <br />
      <br />
      <div className="m-4">
        <p>
          La cousinade Saxcé se profile doucement à l'horizon 2025 🤗 <br />
          <br />
          <p>
            <b>Lieu</b> : <a href="https://www.ermitage-fondacio.fr/">Tiers-Lieu de l'Ermitage</a> <br/>
            23 rue de l'Ermitage, 78000 Versailles
          </p><br/>
          <p>
            <b>Date</b> : Jeudi 29 mai 2025
          </p>
          <br />
          <p>
            <b>Détails pratiques</b> : Infos à venir début 2025 !</p>
          <br />
          <p className="text-justify">
            Si intéressé.e, merci de contacter <a className="text-blue-700 underline " href="mailto:cousinade@desaxce.com">cousinade@desaxce.com</a>.{" "}
          </p>
          <br />
          <p className="text-justify">
            Si vous comptez venir avec des personnes n'ayant pas d'accès
            internet, veuillez également nous le signaler.
          </p>
          <br />
          Toutes suggestions seront les bienvenues !
        </p>
      </div>
    </div>
  );
};

export default About;
