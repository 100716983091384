import React, { useState } from "react";

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

const Poll = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    participants: 1,
    comments: "",
    allowShare: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleInvalidEmail = (e) => {
    e.target.setCustomValidity("Veuillez renseigner une adresse email valide.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://cousinade.hugues-de-saxce.workers.dev/store",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("SUCCESS!", response.status);
        setSubmitted(true);
      } else {
        console.error("FAILED...", response.status);
      }
    } catch (err) {
      console.error("ERROR:", err);
    }
  };

  if (submitted) {
    return (
      <div className="m-auto w-full">
        <br />
        <br />
        <p>Merci pour votre réponse !</p>
        <br />
        <p>Nous vous confirmerons la date et le lieu avant Noël.</p>
      </div>
    );
  }

  const handleInvalidName = (e) => {
    e.target.setCustomValidity("Veuillez renseigner votre nom.");
  };
  const handleInvalidParticipants = (e) => {
    e.target.setCustomValidity("Spécifiez le nombre de participants 1-50.");
  };

  const handleInput = (e) => {
    e.target.setCustomValidity("");
  };

  return (
    <div className="m-auto w-full">
      <form onSubmit={handleSubmit}>
        <br />
        <br />
        <label className="m-4 mb-2 block">
          * Participant :
          <input
            autoFocus
            className="color-gray-900 mb-4 mt-1 w-full border-2 p-2"
            type="text"
            name="name"
            placeholder="Nom et prénom"
            value={formData.name}
            onChange={handleChange}
            onInvalid={handleInvalidName}
            onInput={handleInput}
            disabled
            required
          />
        </label>
        <label className="m-4 mb-2 block">
          * Email :
          <input
            className="color-gray-900 mb-4 mt-1 w-full border-2 p-2"
            type="email"
            name="email"
            placeholder="Adresse email"
            value={formData.email}
            onChange={handleChange}
            onInvalid={handleInvalidEmail}
            onInput={handleInput}
            disabled
            required
          />
        </label>
        <label className="m-4 mb-2 block">
          * Nombre de personnes total :
          <input
            className="color-gray-900 mb-4 mt-1 w-full border-2 p-2"
            type="number"
            name="participants"
            value={formData.participants}
            onChange={handleChange}
            onInvalid={handleInvalidParticipants}
            onInput={handleInput}
            disabled
            min="1"
            max="50"
            required
          />
        </label>
        <label className="m-4 mb-2 block">
          Commentaires / Suggestions :{" "}
          <textarea
            className="color-gray-900 mb-4 mt-1 w-full border-2 p-2"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            disabled
          ></textarea>
        </label>
        <label className="m-4 mb-4 flex items-center">
          <input
            type="checkbox"
            name="allowShare"
            checked={formData.allowShare}
            onChange={handleChange}
            disabled
            className="mr-2"
          />
          Partager ma participation avec les autres participants.
        </label>
        <div className="m-4 flex justify-end">
          <button type="submit" disabled className="btn-primary">
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default Poll;
